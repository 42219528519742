<template>
  <div class="row mt-10 export-master">
    <!-- <modal :adaptive="true" height="auto" name="open_to_settings_table" width="300">
      <div class="row mx-0 py-7">
        <div class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold">
          <div class="col-6 text-center">{{ $t('general.colum_name') }}</div>
          <div class="col-6 text-center">{{ $t('general.add') }}</div>
        </div>
        <div class="col-12  mx-0 py-2 text-xl">
          <draggable v-model="fieldsToViewEdit" v-bind="dragOptions" :move="onMove" class="list-group py-4" tag="ul"
            @end="isDragging=false" @start="isDragging=true">
            <transition-group :name="'flip-list'" type="transition">
              <div v-for="element in fieldsToViewEdit" :key="element.sort"
                class="col-12 list-group-item w-300px d-flex justify-content-between ">
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input v-model="element.type_status" style="font-size: 10px " type="checkbox"></span>
              </div>
            </transition-group>
          </draggable>
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal">
            {{ $t('esc.new_send_close').toUpperCase() }}
          </button>
          <button class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="onSubmitToSaveSettingTable">{{
            $t('esc.new_send')
            }}
          </button>
        </div>

      </div>
    </modal> -->

    <div class="col-12" style="border: 1px solid #0b5a85; background-color: #f3f6f9; border-radius: 12px;">
      <form @submit.prevent="filterResult()">
        <div class="row py-3 px-4" id="filter-card" v-if="filters">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-7 col-md-3 col-sm-12 my-2 row px-0 mx-0">
                <input-filter :helper-text="$t('general.search')" :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')" title="general.search"></input-filter>
              </div>

              <div class="col-12 row mx-0 px-0">
                <div class="col-6 my-2">
                  <date-filter :helper-text="$t('general.start_date')" :model.sync="filters.start_date"
                    name="start_time" title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-6 my-2">
                  <date-filter :helper-text="$t('general.end_date')" :model.sync="filters.end_date" name="end_date"
                    title="general.end_date">
                  </date-filter>
                </div>
              </div>
              
              <div class="col-12 row mx-0 px-0"
                style="margin: 0 !important;">
                <div class="col-6 my-2">
                  <button type="button" @click.prevent="filterResult()"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-6 my-2">
                  <button @click="resetFilters"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
              <!-- <div class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 d-flex align-items-center justify-content-end m-0 px-0 row">
              </div> -->
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-12 mt-10 mx-0 p-0" style="border-radius: 12px;">
      <dashboard-box :title="$t('general.following_export')">
        <template v-slot:preview>

          <!-- <div class="fixed" style="position: fixed !important; top: 220px; right: 15px;">
            <div class="w-60px h-60px" @click="openPopToSettingTable">
              <span v-html="getIconByKey('icons.waybill.edit', {
               class: 'w-40px h-40px d-inline-block object-fill cursor-pointer'
              })">
              </span>
            </div>
          </div> -->
          <div class="export-table">
            <data-table :tableParentFields="[]" :hover="false" :outlined="false" :striped="false"
            :no-border-collapse="true" :borderless="true" :bordered="false" @hitBottom="onHitBottom"
            @onChangeSearchFilter="onChangeSearchFilter" :items="itemsProxy" :fields.sync="tableFields"
            :page.sync="page" :perPage.sync="per_page" 
            @rowClicked="onRowClicked" :infiniteId="infiniteId"
            :sortBy.sync="order" 
            :sortDesc.sync="sort"
            >
          </data-table>
          </div>

        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";

import {
  CREATE_ITEM,
  ERROR,
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ERROR,
  SET_ITEMS,
  SUCCESS,
  UPDATE_ITEM_BY_ID,
  SET_FILTER,
  FILTER
} from "@/core/services/store/following-export/following_export.module";

import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import KTCard from "@/view/content/Card.vue";
import * as _ from "lodash";
import {MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME, FOLLOWING_EXPORT_INDEX_START_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

export default {
  name: "PackingListIndex",
  components: {
    CustomMultiSelect,
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    ButtonWithIcon,
    DateFilter, KTCard
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      packing: CREATE_ITEM,
      filter: FILTER,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost"
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (let [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          let options = [];
          fields.push({
            'key': key,
            'value': this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : '',
            'label': this.$t(value.name),
            'sortable': value.status,
            'sort': value.sort,
            'class': "text-center border border-top-0 border-left-0",
            'tdClass': "cursor-pointer",
            'searchable': value.searchable,
            'searchable_type': value.searchable_type,
            'searchable_options': options,
          })
        }
      }
      return _.sortBy(fields, ['sort']);
    },
    itemsProxy: {
      get() {
        let results = [];

        if (!this.items || this.items.data === null || this.items.data === undefined) return results;

        let items = this.items;
        if (items.data) {
          let color_names = [];
          let color_numbers = [];
          let body_sizes = [];
          items.data.forEach(item => {
            let total_quantity = 0;
            item.orderable.order_size_and_colors.forEach(orderSizeAndColorItem => {
              color_names.push(orderSizeAndColorItem.color_name)
              color_numbers.push(orderSizeAndColorItem.color_number)
              let orderBodySizes = _.values(JSON.parse(orderSizeAndColorItem.body_sizes));
              orderBodySizes.forEach(orderBodySize => {
                body_sizes.push(orderBodySize.name)
                total_quantity += Number(orderBodySize.amount);
              })
            })

            color_names = _.uniq(color_names);
            body_sizes = _.uniq(body_sizes);
            results.push({
              id: item.id,
              order_number: item.orderable.order_number,
              customer_name: item.orderable.company.name,
              supplier_company: item.supplier_company ? item.supplier_company.name : '-',
              color_name: color_names.join(','),
              color_number: color_numbers.join(','),
              body_size: body_sizes.join(','),
              amount: total_quantity,
              total: item.amount,
              remain: total_quantity - Number(item.amount),
              date: (item.created_at) ? moment(item.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '',
            })
          })
        }
        return results
      },
      set(value) {
        // this.setItems(value);
      },
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        }
      ],
      url: 'api/export',
      orderUrl: 'api/orders',
      page: 1,
      per_page: 10,
      sort: "desc",
      order: "id",
      items: [],
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, 'd'),
      search: null
    };
  },
  methods: {
    ...mapMutations({
      setItems: SET_ITEMS,
      setError: SET_ERROR,
      setFilter: SET_FILTER,
    }),
    onRowClicked(item, index, event) {
      if (this.isUserGranted('Export', ['viewAny'])) {
        this.$router.push({
          name: 'following_export.packing_list.detail',
          params: {
            id: this.items.data[index].id,
          }
        });
      }

      return false;
    },
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
    getTableItem() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/model_table',
        filters: {
          model_name: 'following_export'
        },
        acceptPromise: true,
        showLoading: false,
      }).then(result => {
        if (result.status) {
          for (let i = 0; i < result.data.length; i++) {
            self.modelTableItem = result.data;
            self.modelTableItem[i].table_fields = JSON.parse(result.data[i].table_fields)
          }
          for (const [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
            self.fieldsToViewEdit.push({
              'name': this.$t(value.name),
              'sort': value.sort,
              'type': value.type,
              'type_status': value.status,
              'key': key,
              'searchable': !!value.searchable,
              'searchable_type': value.searchable_type,
              'searchable_options': value.searchable_options,
            })
            self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ['sort']);
          }
        }
      });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort((one, two) => {
        return one.sort - two.sort;
      });
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.status) && !draggedElement.status
      );
    },
    openPopToSettingTable() {
      this.$modal.show('open_to_settings_table')
    },
    closeModal() {
      this.$modal.hide('open_to_settings_table');
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};

      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
          searchable: !!this.fieldsToViewEdit[i].searchable,
          searchable_type: this.fieldsToViewEdit[i].searchable_type,
        };
      }

      let contents = {
        model_name: 'following_export',
        table_fields: json,
      }
      let payload = {
        url: 'api/model_table/' + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      }
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then(result => {
        if (result.status) {
          this.$modal.hide('open_to_settings_table');
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          this.fieldsToViewEdit = [];
          this.getTableItem();

        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    onHitBottom($state = null) {
      let self = this;

      if (!this.isUserGranted('Export', ['viewAny'])) {
        return false;
      }

      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'order', this.order);
      this.$set(filters, 'sort', this.sort);

      this.$store.dispatch(HANDLE_INFINITE_SCROLL, {
        filters: filters,
        url: this.url,
        '$state': $state,
      }).then(result => {
        if (result.status) {
          self.items = result.data;
          if (!(result.data && result.data.data && result.data.data.length)) {
            $state.complete();
          }
          if ($state) {
            $state.loaded();
          }
          if (self.itemId) {
            self.getPackingListItem();
          }
        } else {
          if ($state) {
            $state.complete();
          }
        }
      })
    },
    filterResult() {
      localStorage.setItem(MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(FOLLOWING_EXPORT_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.end_date));

      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onHitBottom();
      if (self.state) {
        setTimeout(() => {
          self.state.reset();
        }, 100);
      }
    },
    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        search: null,
        status_id: 28
      })
    },
  },
  created() {
    this.setItems({
      id: null,
      name: null,
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.management.following_export") }
    ]);

    if (this.isUserGranted('Export', ['viewAny'])) {
      this.getTableItem();

      if (!this.filter) {
        this.resetFilters();
      }
      this.filters.end_date = localStorage.hasOwnProperty(FOLLOWING_EXPORT_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(FOLLOWING_EXPORT_INDEX_START_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(MODEL_HOUSE_INDEX_START_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

      setTimeout(this.onHitBottom, 500);
    }
  },
  watch: {
    per_page: function (newValue, oldValue) {
      this.page = 1;
      this.$store.dispatch(GET_ITEMS, { url: this.url, filters: this.filters })
    },
    page: function (newValue, oldValue) {
      this.$store.dispatch(GET_ITEMS, { url: this.url, filters: this.filters })
    },
    sort() {
      this.onHitBottom();
    },
    order() {
      this.onHitBottom();
    },

  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/following-export/followingExportIndex.scss";
/*Mobile End*/
</style>
